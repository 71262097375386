&.landing_page_extra {

	.content {
		h2, h3, h4, h5, h6 { color: $black;}
	}

	.block-section {
		.grid,
		.slider { margin-top: 30px;}

		/* grid */
		.grid {
			.card {
				background-color: $white;
				.card-body {
					padding: 15px;
					.card-subtitle {
						color: $primary;
					}
				}
			}
		}

		/* slider */
		.slider {
			.item {
				&::after {
					display: none;
				}
				.card {
					background-color: $white;
					.card-body {
						padding: 15px;
						.card-description {
							
						}
						.card-subtitle {
							color: $primary;
						}
					}
				}
			}
		}
	}

}