&.home {
	.main {
		padding: 0;

		.content-narrow-wide {
			&:first-child {
				background: $bg-base-1;
				padding: 20px 0 60px;
				margin-bottom: 210px;

				@include media-breakpoint-up(md) {
					padding: 60px 0;
				}

				.container-holder {
					@include media-breakpoint-down(sm) {
						padding-bottom: 60px;
					}
				}

				.column.one {
					margin-bottom: 40px;

					@include media-breakpoint-down(sm) {
						position: static;
					}
					@include media-breakpoint-up(md) {
						margin-bottom: 20px;
					}

					h1 {
						text-align: right;
						color: $white;
					}

					.grid.page_block.contentblock {
						flex-direction: column;
						margin: 0;

						.item {
							flex: 1 !important; // !important needed to override base css
							max-width: none;
							padding: 0;
							margin: 0;
							border: none;
							box-shadow: none;
							border-radius: 0;

							&:not(:last-child) {
								border-bottom: 1px solid $white;
							}

							a {
								display: flex;
								width: 100%;
								flex-direction: row;
								align-items: center;
								box-shadow: none;
								border-radius: 0;
								padding: 10px 10px 10px 0;
								transition: 0.3ms ease-in-out;

								.card-image {
									order: 2;
									font-size: $font-size-base;
									background: transparent;
									width: 2rem;
								}

								.card-body {
									padding: 0 !important; // !important needed to override base css

									.card-caption {
										display: flex;
										justify-content: space-between;
										align-items: center;

										h3,
										h4 {
											margin: 0;
										}

										h3 {
											color: $primary;
											font-weight: 800;
											padding-right: 20px;
											position: relative;

											&:hover {
												text-decoration: underline;
												color: lighten($primary, 5);
											}

											&::after {
												position: absolute;
												top: 50%;
												right: 0;
												content: "\f178";
												transform: translateY(-50%);
												font-size: $font-size-base_sm;
												font-family: $font-awesome;
												font-weight: $font-weight-bold;
												transition: 0.25s ease-in-out;
											}
										}

										h4 {
											color: $white;
											font-size: $font-size-base_sm;
											font-weight: 400;
											margin-right: 15px;
										}
									}
								}

								&:hover {
									text-decoration: none;

									.card-body {
										.card-caption {
											h3 {
												&::after {
													right: -5px;
													transition: 0.3ms ease-in-out;
												}
											}
										}
									}
								}
							}
						}
					}

					a.btn.page_block.link {
						margin-top: 20px;
						@extend .btn-primary;

						@include media-breakpoint-down(sm) {
							position: absolute;
							bottom: 0;
						}

						&::after {
							content: none;
						}
					}
				}
			}
		}

		.content.content-wide-narrow {
			background: $bg-base-2;
			margin-bottom: 210px;
			padding: 60px 0 0;

			@include media-breakpoint-up(md) {
				padding: 60px 0;
			}

			.container-two-columns {
				.container-holder {
					.column.one {
						order: 2;

						@include media-breakpoint-up(md) {
							order: 1;
						}
					}

					.column.two {
						order: 1;

						@include media-breakpoint-up(md) {
							order: 2;
						}

						h1 {
							font-size: $h1-font-size;
							color: $white;
							text-align: right;
							max-width: 350px;
							line-height: 1;
						}

						h3 {
							font-size: $font-size-base;
							margin-bottom: 0;

							@include media-breakpoint-up(md) {
								font-size: $h3-font-size;
							}

							a {
								font-size: $font-size-base;
								position: relative;
								padding-right: 40px;

								@include media-breakpoint-up(md) {
									font-size: $font-size-base * 1.125;
								}

								&:hover {
									text-decoration: underline;
									color: lighten($primary, 5);

									&::after {
										right: 15px;
										transition: 0.2s ease-in;
									}
								}

								&::after {
									position: absolute;
									top: 50%;
									right: 20px;
									content: "\F178";
									transform: translateY(-50%);
									font-size: $font-size-base_sm;
									font-weight: 400;
									font-family: $font-awesome;
									transition: all 300ms;
								}
							}
						}

						.btn.page_block.link {
							margin: 15px 0 35px;
							@extend .btn-primary;
						}
					}
				}
			}
		}

		.content.content-narrow-wide:nth-child(3) {
			background: $bg-base-3;
			margin-bottom: 210px;
			padding: 60px 0 0;

			@include media-breakpoint-up(md) {
				padding: 60px 0;
			}

			.container-two-columns {
				.container-holder {
					.column.one {
						h1 {
							font-size: $h1-font-size;
							color: $white;
							max-width: 334px;
							line-height: 1;

							@include media-breakpoint-up(md) {
								margin-bottom: 50px;
							}
						}

						h3 {
							font-size: $font-size-base;
							margin-bottom: 0;

							@include media-breakpoint-up(md) {
								font-size: $h3-font-size;
							}

							a {
								font-size: $font-size-base;
								position: relative;
								padding-right: 40px;

								@include media-breakpoint-up(md) {
									font-size: $font-size-base * 1.125;
								}

								&:hover {
									text-decoration: underline;
									color: lighten($primary, 5);

									&::after {
										right: 15px;
										transition: 0.2s ease-in;
									}
								}

								&::after {
									position: absolute;
									top: 50%;
									right: 20px;
									content: "\F178";
									transform: translateY(-50%);
									font-size: $font-size-base_sm;
									font-weight: 400;
									font-family: $font-awesome;
									transition: all 300ms;
								}
							}
						}

						.btn.page_block.link {
							margin: 10px 0 35px;
							@extend .btn-primary;
						}
					}
				}
			}
		}

		.content.booking-widget {
			background: $bg-base-5;
			padding: 60px 0;

			.container-default {
				.container-holder {

					@include media-breakpoint-up(lg) {
						padding: 0 90px;
					}

					h1,
					h3,
					p {
						color: $white;
					}

					h3 {
						font-size: $font-size-base;
					}
				}
			}
		}

		.home-blocks {
			background: $bg-base-5;
			padding: 0 0 80px;

			.container-default {
				padding: 0;

				@include media-breakpoint-up(md) {
					padding: 0 15px !important;
				}

				.container-holder {
					.owl-carousel {
						min-height: auto;

						.owl-stage-outer {
							.owl-stage {
								.owl-item {
									.item {
										&::after {
											@extend .d-none;
										}

										.card {
											position: relative;
											box-shadow: none;
											border-radius: 0;
											width: auto;

											&::after {
												content: "";
												position: absolute;
												background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba($primary, 1) 100%);
												bottom: 0;
												left: 0;
												right: 0;
												height: 90px;
											}

											&-body {
												position: absolute;
												display: block !important; // !important needed to override base css
												bottom: 0;
												left: 10px;
												z-index: 1;

												.card-caption {
													.card-title {
														position: relative;
														font-size: $card-title-font_size;
														color: $card-title-color;
														line-height: 1;
														margin-bottom: 5px;
														padding-right: 28px;
														max-width: 275px;
														white-space: pre;
														overflow: hidden;
														text-overflow: ellipsis;

														&::after {
															position: absolute;
															top: 50%;
															right: 6px;
															content: "\F178";
															transform: translateY(-50%);
															font-size: $font-size-base;
															font-weight: 400;
															font-family: $font-awesome;
															transition: all 300ms;
														}
													}

													.card-subtitle {
														font-size: $card-subtitle-font_size;
														color: $card-subtitle-color;
														font-weight: 400;
														line-height: 1;
														margin-bottom: 5px;
													}

													.card-description {
														font-size: $card-description-font_size;
														line-height: 1;

														&::after {
															background: transparent;
														}
													}
												}
											}

											&:hover {
												.card-title {
													&::after {
														right: 0;
														transition: 0.2s ease-in-out;
													}
												}
											}
										}
									}
								}
							}
						}

						.owl-dots {
							bottom: 45px;
							right: 40px;
							left: auto;
							justify-content: flex-end;

							.owl-dot {
								&.active span {
									background: $carousel-dots-button-background--active;
								}

								span {
									border: $carousel-dots-button-border;
								}
							}
						}
					}
				}
			}
		}
	}
}
