&.contact {
	.main {
		.container-two-columns {
			.column {
				&.one {
					.back_to_overview_link,
					h1 {
						display: inline;
					}

					h3 {
						margin-top: 30px;
					}

					.btn-back {
						&:before {
							color: $white;
							content: '\f177';
							font-size: 36px;
							font-weight: 400;
						}
					}

					h1 {
						color: $white;
						margin-top: 6px;
					}

					.btn {
						background: $primary;
						color: $white;
					}
				}

				&.two {
					.form {
						background: rgba($white, 0.5);
						padding: 40px 30px;

						form,
						small {
							width: 80%;
							margin: auto;
							display: block;
							@include media-breakpoint-down(sm) {
								width: 100%;
							}
						}
					}
				}
			}
		}

		.map-section {
			padding-bottom: 3px;

			.container-fluid {
				padding: 0;
			}
		}
	}
}
