.content {
	p,
	ul:not(.vocabulary_term_list) li {
		font-size: $font-size-base_sm;
	}
	a:not(.btn) { text-decoration: underline;}
}

.content,
.map-section,
.content-blocks,
.gallery-section,
.page-title-section {
	background-color: $white;
}
