&.two_column_page {
	.main {
		.content.content-narrow-wide {
			padding: 60px 0;

			.container-two-columns {
				.container-holder {
					.column.one {
						flex: 0 0 100%;
						max-width: 100%;

						.back_to_overview_link {
							.btn-back {
								&::before {
									top: -5px;
									@extend .btn-absolute;
								}
							}
						}

						h1 {
							color: $white;
							text-align: right;
							line-height: 1;
							margin-bottom: 0;
						}

						h2 {
							text-align: right;
							color: $primary;
							margin-bottom: 55px;
						}

						h3 {
							color: $primary;
						}

						p {
							max-width: none;
							line-height: 1.7;

							a {
								margin-bottom: 20px;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.content {
			padding: 60px 0;

			.container-two-columns {
				.container-holder {
					.column.one {
						margin-bottom: 60px;
						
						@include media-breakpoint-up(md) {
							margin-bottom: 15px;
						}
						@include media-breakpoint-up(lg) {
							padding-left: 80px;
						}
					}

					.column.one,
					.column.two {
						h3 {
							color: $primary;
						}
						p {
							max-width: 380px;
							line-height: 1.7;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
