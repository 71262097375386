/* body
   ========================================================================== */
&.default {
	background-image: url('/images/body-bg.jpg'); // default bg image
	@include media-breakpoint-down(sm) {
		background-size: 50%;
	}
	@include media-breakpoint-up(md) {
		background-attachment: fixed;
	}
}

// common reusable placeholders classes
.flex-center {
	display: flex;
	align-items: center;
}
.list-default {
	margin: 0;
	padding: 0;
	list-style: none;
}

// common column size
.content-narrow-wide {
	.column.one {
		@include media-breakpoint-up(lg) {
			@include make-col(5);
			padding-left: 80px;
			padding-right: 0;
		}
	}
	.column.two {
		@include media-breakpoint-up(lg) {
			@include make-col(7);
			padding-left: 15px !important;
		}
	}
}
.content-wide-narrow {
	.column.one {
		@include media-breakpoint-up(lg) {
			@include make-col(8);
		}
	}
	.column.two {
		@include media-breakpoint-up(lg) {
			@include make-col(4);
			padding-left: 0 !important; // !important was needed to override base styles
		}
	}
}

@include media-breakpoint-down(sm) {
	// Specific for mobile. Not used mobile first approach to avoid  lots of overrides.
	section.home-blocks .container-default.container {
		padding: 0;
	}
	.container-two-columns.container {
		padding: 0;
		.container-holder {
			margin: 0;
		}
	}
	.content-narrow-wide {
		.column.one {
			padding: 0 30px !important; // !important was needed to override base styles
		}
		.column.two {
			padding: 0 !important; // !important was needed to override base styles
			margin: 0;
		}
	}
	.content-wide-narrow {
		.column.one {
			padding: 0 !important; // !important was needed to override base styles
			margin: 0;
		}
		.column.two {
			padding: 0 30px !important; // !important was needed to override base styles
		}
	}
}

// page-title-section 
.page-title-section {
	padding: 50px 0;

	.container-holder {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.back_to_overview_link,
		.btn-back,
		h1 {
			color: $white;
		}
		h1 { margin-bottom: 0;}
	}
}

// common element styles
p {
	font-size: $font-size-base * 1.188;
}
img {
	max-width: 100%;
}

// common gallery section
.gallery-section {
	.container-default {
		padding: 1px 0 0 0 !important; // !important was needed to override base styles

		@include media-breakpoint-up(lg) {
			padding: 0 !important; // !important was needed to override base styles
		}
		.container-holder {
			.gallery {
				margin: 30px 0 0;
				justify-content: center;

				@include media-breakpoint-up(lg) {
					margin: 0 !important; //!important was needed to override base styles
				}
				.gallery-item {
					&:hover {
						transform: scale(1);
					}
					@include media-breakpoint-down(sm) {
						flex: 0 0 100%;
						max-width: 100%;
					}
					@include media-breakpoint-down(md) {
						padding: 0 !important;
					}
					@include media-breakpoint-up(lg) {
						flex: 0 0 33.33%;
						max-width: 33.33%;
						margin: 0;
						padding: 0 !important; //!important was needed to override base styles
					}
					.gallery-link {
						overflow: hidden;

						&:hover {
							.gallery-img {
								transform: scale(1);
							}
						}
						.gallery-img {
							width: 100%;
							height: auto;
							transition: all 0.5s ease-in-out;
							transform: scale(1.2);
						}
					}
				}
			}
		}
	}
}

// common back button style
.btn-absolute {
	position: absolute;
}
.btn-back-to-page {
	font-family: $font-awesome;
	font-size: $font-size-base * 2.25;
	font-weight: 400;
	border: none;
	content: "\F178" !important; // !important needed to override base css
	transform: rotate(180deg);
	transition: 0.25s ease-in-out;
	display: inline-block;
}
.back_to_overview_link {
	position: relative;

	.btn-back {
		color: $white;

		&::before {
			@extend .btn-back-to-page;
		}
		&:hover {
			&::before {
				margin-left: -5px;
				transition: 0.3ms ease-in-out;
			}
		}
	}
}

// card columns
.content {
	padding: 60px 0;

	.container-default {
		.container-holder {
			.page_block.card-columns {
				@include media-breakpoint-up(xl) {
					column-count: 3;
				}
			}
		}
	}
}
