&.service_overview {
	.main {
		.content.content-narrow-wide {
			padding: 60px 0;

			.container-two-columns {
				.container-holder {
					.column.one {
						.back_to_overview_link {
							.btn-back {
								&::before {
									//@extend .btn-absolute;
									//transform: translateY(-15%) rotate(180deg);
								}
							}
						}

						h1 {
							color: $white;
							line-height: 1;
							margin-bottom: 35px;
						}

						h3 {
							color: $primary;

							a {
								position: relative;
								padding-right: 40px;

								&:hover {
									text-decoration: none;

									&::after {
										right: 15px;
										transition: 0.2s ease-in;
									}
								}

								&::after {
									position: absolute;
									top: 50%;
									right: 20px;
									content: "\F178";
									transform: translateY(-50%);
									font-size: $font-size-base_sm;
									font-weight: 400;
									font-family: $font-awesome;
									transition: all 300ms;
								}
							}
						}

						p {
							line-height: 1.7;
						}
					}
				}
			}
		}
	}
}

&.service_detail {
	.main {
		.content {
			padding-bottom: 0;
			background: $bg-base-2 !important;
		}

		.container-holder {
			.service_detail {
				margin: 0;

				.intro {
					@extend .container;
					margin-bottom: 60px;

					@include media-breakpoint-up(md) {
						display: flex;
					}

					.info {
						flex: 0 0 35%;
						max-width: 35%;
						@include media-breakpoint-down(sm) {
							flex: 0 0 100%;
							max-width: 100%;
						}

						.btn-back,
						h1 {
							flex: 0 1 auto;
						}

						.btn-back {
							&:hover {
								&::before {
									margin-left: -5px;
									transition: 0.3ms ease-in-out;
								}
							}

							&::before {
								@extend .btn-absolute;
								@extend .btn-back-to-page;
								transform: translateY(-15%) rotate(180deg);
							}
						}

						p {
							flex: 0 1 100%;
							max-width: 350px;
							line-height: 1.7;
						}

						h1 {
							padding-left: 40px;
							text-align: right;
							line-height: 1;
							margin-bottom: 35px;
						}

						h3 {
							font-size: 1.125rem;
							line-height: 1.2;
							color: $primary;

							a {
								position: relative;
								padding-right: 40px;

								&:hover {
									text-decoration: none;

									&::after {
										right: 15px;
										transition: 0.2s ease-in;
									}
								}

								&::after {
									position: absolute;
									top: 50%;
									right: 20px;
									content: "\F178";
									transform: translateY(-50%);
									font-size: $font-size-base_sm;
									font-weight: 400;
									font-family: $font-awesome;
									transition: all 300ms;
								}
							}
						}

						p {
							line-height: 1.7;
						}
					}

					.info-extra {
						flex: 0 0 65%;
						max-width: 65%;
						@include media-breakpoint-down(sm) {
							flex: 0 0 100%;
							max-width: 100%;
						}

						.owl-carousel {
							@include media-breakpoint-only(sm) {
								min-height: auto;
							}
						}
					}
				}

				.image-gallery {
					.gallery {
						margin: 0 !important;
						padding: 0;

						.gallery-item {
							margin: 0;
							padding: 0;

							&:hover {
								.gallery-img {
									transform: scale(1);
								}
							}
						}
					}
				}
			}
		}
	}
}

&.service_category {
	.main {
		.content {
			background: $bg-base-2 !important;
		}

		.container-default {
			@extend .container;
		}

		.container-holder {
			.service_category_detail {
				width: 100%;
				padding-bottom: 60px !important;
				margin-left: -15px;
				margin-right: -15px;

				.intro {
					width: 100%;
					@include media-breakpoint-up(md) {
						display: flex;
					}

					.info {
						flex: 0 0 35%;
						max-width: 35%;

						@include media-breakpoint-down(sm) {
							flex: 0 0 100%;
							max-width: 100%;
						}

						.btn-back,
						h1 {
							flex: 0 1 auto;
						}

						p {
							flex: 0 1 100%;
							max-width: 350px;
							line-height: 1.7;
						}

						.btn-back {
							&:hover {
								&::before {
									margin-left: -5px;
									transition: 0.3ms ease-in-out;
								}
							}

							&::before {
								@extend .btn-absolute;
								@extend .btn-back-to-page;
								transform: translateY(-15%) rotate(180deg);
								font-size: $font-size-base *  1.75;
							}
						}

						h1 {
							padding-left: 30px;
							text-align: right;
							line-height: 1;
							margin-bottom: 35px;
						}

						h3 {
							font-size: 1.125rem;
							line-height: 1.2;
							color: $primary;

							a {
								position: relative;
								padding-right: 40px;

								&:hover {
									text-decoration: none;

									&::after {
										right: 15px;
										transition: 0.2s ease-in;
									}
								}

								&::after {
									position: absolute;
									top: 50%;
									right: 20px;
									content: "\F178";
									transform: translateY(-50%);
									font-size: $font-size-base_sm;
									font-weight: 400;
									font-family: $font-awesome;
									transition: all 300ms;
								}
							}
						}

						p {
							line-height: 1.7;
						}

						ul {
							list-style-type: none;
							padding-left: 0;

							li {
								position: relative;
								padding-left: 30px;

								&:before {
									font-family: $font-awesome;
									font-size: $font-size-base_sm;
									font-weight: 600;
									content: "\f00c";
									position: absolute;
									left: 0;
								}
							}
						}
					}

					.info-extra {
						flex: 0 0 65%;
						max-width: 65%;
						@include media-breakpoint-down(sm) {
							flex: 0 0 100%;
							max-width: 100%;
						}

						.owl-carousel {
							@include media-breakpoint-only(sm) {
								min-height: auto;
							}
						}
					}
				}

				.extra-descriptions {
					@include media-breakpoint-up(md) {
						display: flex;
					}
					margin-top: 25px;

					.extra-description-1 {
						@include media-breakpoint-up(md) {
							flex: 0 0 50%;
							max-width: 50%;
						}
						padding-left: 15px;
						padding-right: 3rem;
					}

					.extra-description-2 {
						@include media-breakpoint-up(md) {
							flex: 0 0 50%;
							max-width: 50%;
							padding-right: 15px;
						}
						padding-left: 15px;
					}
				}
			}
		}
	}
}
