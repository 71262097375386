&.three_column_page {
	.main {
		.content.content-narrow-wide {
			padding: 60px 0;

			.container-two-columns {
				.container-holder {
					.column.one {
						@include media-breakpoint-up(md) {
							padding-left: 15px;
						}

						.back_to_overview_link {
							.btn-back {
								&::before {
									@extend .btn-absolute;
								}
							}
						}

						h1 {
							color: $white;
							text-align: right;
							line-height: 1;
							margin-bottom: 0;
						}

						h2 {
							text-align: right;
							color: $primary;
							margin-bottom: 55px;
						}

						h3 {
							color: $primary;
						}

						p {
							line-height: 1.7;

							a {
								margin-bottom: 20px;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.content {
			padding: 0;
			@include media-breakpoint-up(sm) {
				padding: 0 0 60px;
			}

			.container-three-columns {
				.container-holder {
					.column {
						@include media-breakpoint-up(md) {
							padding-left: 0;
							margin-bottom: 0;
						}
						@include media-breakpoint-down(sm) {
							padding: 0 30px !important; // !important was needed to override base
						}

						h3 {
							color: $primary;
						}

						p {
							max-width: 350px;
							line-height: 1.7;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}

					&:not(:last-child) {
						margin-bottom: 60px;
					}
				}
			}
		}
	}
}
