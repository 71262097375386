$body-padding: 218px 0 0;
$body-sticky-padding: 0;

/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   $container-padding_sm: 0 15px;


/* ==========================================================================
   Custom theme-colors
   ========================================================================== */

   // Background variables
   $bg-base-1: #89B9A0;
   $bg-base-2: #9AC2C9;
   $bg-base-3: #F27C00;
   $bg-base-4: #ebddc9;
   $bg-base-5: #4D8FBA;
   $bg-base-6: #0E76BC;
   $bg-base-7: #E0EDEF;


/* ==========================================================================
   content
   ========================================================================== */

/* content > h1
   ========================================================================== */
$content-h1-margin: 0 0 30px !default;
$content-h1-font_family: $headings-font-family !default;
$content-h1-font_size: $h1-font-size !default;
$content-h1-color: $white !default;
$content-h1-color--dark: $white !default;

/* content > h2
   ========================================================================== */
$content-h2-margin: 0 0 $headings-margin-bottom !default;
$content-h2-font_family: $headings-font-family !default;
$content-h2-font_size: $h2-font-size !default;
$content-h2-color: $secondary !default;
$content-h2-color--dark: $secondary !default;

/* content > h3
   ========================================================================== */
$content-h3-font_family: $font-family-base !default;
$content-h3-font_size: $h3-font-size !default;
$content-h3-color: $secondary !default;
$content-h3-color--dark: $secondary !default;

/* content > h4
   ========================================================================== */
$content-h4-margin: 0 0 $headings-margin-bottom !default;
$content-h4-font_family: $headings-font-family !default;
$content-h4-font_size: $h4-font-size !default;
$content-h4-color: $body-color !default;
$content-h4-color--dark: $white !default;

/* content > p
   ========================================================================== */
$content-p-font_size: $font-size-base_sm !default;

/* ==========================================================================
   mini-sab
   ========================================================================== */
$mini-sab-padding: 0 !default;
$mini-sab-background: none !default;

/* ==========================================================================
   owl-carousel
   ========================================================================== */
$carousel-min_height: 300px !default;
$carousel-min_height_lg: 445px !default;

$carousel-subtitle-font_size: $font-size-base_sm;
$carousel-description-font_size: $font-size-base_sm;

/* owl-carousel > owl-dots */
$carousel-dots-button-background--hover: $white !default;
$carousel-dots-button-background--active: $white !default;
$carousel-dots-button-border: 1px solid $white !default;
$carousel-dots-button-border--hover: 1px solid $white !default;
$carousel-dots-button-border--active: 1px solid $white !default;
$carousel-dots-button-box_shadow: none !default;

/* ==========================================================================
   card
   ========================================================================== */
$card-background: transparent;
$card-border: none !default;

/* card > card-title */
$card-title-font_size: $h4-font-size !default;
$card-title-color: $white !default;

/* card > card-subtitle */
$card-subtitle-font_size: $font-size-base_sm !default;
$card-subtitle-color: $white !default;

/* card > card-description */
$card-description-max_height: auto !default;
$card-description-margin: 0 !default;
$card-description-font_size: $font-size-base_sm !default;
$card-description-font_family: $font-family-base !default;
$card-description-color: $white !default;
