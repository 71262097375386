.owl-carousel {
	min-height: $carousel-min_height;

	@include media-breakpoint-up(sm) {
		min-height: $carousel-min_height_lg;
	}

	.item {
		min-height: $carousel-min_height;

		@include media-breakpoint-up(md) {
			min-height: $carousel-min_height_lg;
		}

		&::after {
			content: "";
			position: absolute;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba($primary, 1) 100%);
			bottom: 0;
			left: 0;
			height: 90px;
			width: 100%;
		}
	}

	.owl-dots {
		bottom: 40px;
		right: 20px;
		left: auto;

		.owl-dot span {
			width: 12px;
			height: 12px;
		}
	}

	.owl-stage-outer {
		position: relative;

		.owl-stage {
			.owl-item {
				.item {
					.owl-container {
						position: absolute;
						bottom: 20px;
						left: 20px;
						padding: 0;

						.owl-caption {
							display: inline-flex;
							flex-direction: column;
							z-index: 1;
							position: relative;
							max-width: calc(100% - 60px);

							.owl-btn {
								order: 1;
								background: none;
								padding: 0;
								font-size: $h3-font-size;
								font-weight: 800;
								margin-bottom: 0;
								box-shadow: none;
								border: none;
								transition: 0.3s ease-in-out;

								&:hover {
									&::after {
										margin-left: 15px;
									}
								}
							}

							.owl-title {
								display: none;
							}

							.owl-subtitle {
								order: 3;
								margin: 0;
								font-weight: normal;
								font-size: $carousel-subtitle-font_size;
							}

							.owl-description {
								order: 4;
								margin: 0;
								font-size: $carousel-description-font_size;
							}

							.owl-btn {
								position: relative;
								padding-right: 20px;
								text-align: left;

								&:hover {
									&::after {
										transition: 0.2s ease-in;
									}
								}

								&::after {
									position: absolute;
									top: 50%;
									left: auto;
									margin-left: 10px;
									content: "\F178";
									transform: translateY(-50%);
									font-size: $font-size-base_sm;
									font-weight: 400;
									font-family: $font-awesome;
									transition: all 300ms;
								}
							}
						}
					}
				}
			}
		}
	}

	.owl-nav {
		display: none;
	}
}
