&.one_column_page {
	.main {
	
		.content {
			//background: $bg-base-7 !important; // !important needed to override base css
			padding: 50px 0; // !important needed to override base css

			@include media-breakpoint-up(lg) {
				padding: 50px 0 140px;
			}
		}
	}
}
