&.landing_page {
	.main {
		.content.content-narrow-wide {
			padding: 60px 0;
			margin-bottom: 210px;

			.container-two-columns {
				.container-holder {
					.column.one {
						@include media-breakpoint-up(md) {
							padding-left: 15px;
						}

						.back_to_overview_link {
							.btn-back {
								&::before {
									@extend .btn-absolute;
								}
							}
						}

						h1 {
							position: relative;
							color: $white;
							text-align: right;
							line-height: 1;
							margin-bottom: 0;
						}

						h2 {
							text-align: right;
							color: $primary;
							margin-bottom: 55px;
						}

						h3 {
							color: $primary;
						}

						p {
							max-width: none;
							line-height: 1.7;

							a {
								margin-bottom: 20px;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.content.content-wide-narrow {
			padding: 60px 0;
			margin-bottom: 210px;

			.container-two-columns {
				.container-holder {
					.column.one {
						order: 2;
						margin-bottom: 60px;

						@include media-breakpoint-up(md) {
							order: 1;
							padding-left: 0;
							margin-bottom: 0;
						}
					}

					.column.one,
					.column.two {

						@include media-breakpoint-down(sm) {
							padding: 0 30px !important; // !important was needed to override base
						}

						h3 {
							color: $primary;
						}

						p {
							max-width: 350px;
							line-height: 1.7;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}

					.column.two {
						order: 1;

						@include media-breakpoint-up(md) {
							order: 2;
						}

						h1 {
							color: $white;
							text-align: right;
							max-width: 350px;
							line-height: 1;
						}
					}
				}
			}
		}

		.content-blocks {
			padding: 60px 0;
			margin-bottom: 210px;

			.container-default {
				.container-holder {
					.page_block.grid {
						.item {
							@include media-breakpoint-up(lg) {
								flex: 0 0 33.33% !important; // !important was needed to override base
								max-width: 33.33% !important; // !important was needed to override base
								padding-left: 0;
								padding-right: 0;
							}
						}
					}
				}
			}
		}
	}
}
