/* bootstrap > theme-colors
   ========================================================================== */
$primary: #032744 !default;
$secondary: #005CAA !default;

/* bootstrap > font
	========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@200;400;600;700;900&display=swap');

$font-catamaran: 'Catamaran', sans-serif;
$font-awesome: "Font Awesome 5 Pro";

$font-family-base: $font-catamaran !default;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.125 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base_sm !default;
$h6-font-size: $font-size-base_xs !default;

/* bootstrap > typography
   ========================================================================== */
$headings-font-family: $font-catamaran !default;
$headings-font-weight: 900;


/* body - colors
   ========================================================================== */

   // blue
   .w3-body-color-blue {
      .content,
      .map-section,
      .gallery-section,
      .page-title-section {
         background-color: #9AC2C9 !important;
      }
      .content-blocks,
      .content:last-of-type,
      .bg-light {
         background-color: #E0EDEF !important;
      }
   }

   // green
   .w3-body-color-green {
      .content,
      .map-section,
      .gallery-section,
      .page-title-section {
         background-color: #89B9A0 !important;
      }
      .content-blocks,
      .content:last-of-type,
      .bg-light {
         background-color: #DBE9E2 !important;
      }
   }

   // red
   .w3-body-color-red {
      .content,
      .map-section,
      .gallery-section,
      .page-title-section {
         background-color: #CEABA4 !important;
      }
      .content-blocks,
      .content:last-of-type,
      .bg-light {
         background-color: #F1E9E7 !important;
      }
   }

   // brown
   .w3-body-color-brown {
      .content,
      .map-section,
      .gallery-section,
      .page-title-section {
         background-color: #F27C00 !important;
      }
      .content-blocks,
      .content:last-of-type,
      .bg-light {
         background-color: #ECE4D1 !important;
      }
   }

   // turquoise
   .w3-body-color-turquoise {
      .content,
      .map-section,
      .gallery-section,
      .page-title-section {
         background-color: #4D8FBA !important;
      }
      .content-blocks,
      .content:last-of-type,
      .bg-light {
         background-color: #E0EDEF !important;
      }
   }
