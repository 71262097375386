/* https://tommybookingsupport.com/widget/css/tommy.style.css */
.header .logo { width: unset;}
.main { padding: unset;}
.nav-collapse .menu > li > a { padding: unset;}
.nav-toggle { padding: unset;}

/* TOMMY RESET/IMPROVEMENTS */
.tommy-matrix { width: 100% !important; margin-bottom: 30px;}
.tommy-matrix .the-matrix { font-family: 'Catamaran', sans-serif;}
.tommy-matrix .the-matrix .vertical-align { display: flex; flex-direction: column;}
.tommy-matrix .matrix-scroll { padding-bottom: 0;}
.tommy-matrix .matrix-bar fieldset { border: 0; padding: 0; margin: 0; }
.tommy-matrix .matrix-bar label::after { display: none; }
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point {margin: 0 1px 0 0;}
.tommy-matrix .popup .close { cursor: pointer;}
.tommy-matrix .the-matrix.periods .matrix-row .fixed-one,
.tommy-matrix .the-matrix.periods .matrix-beschikbaarheid,
.tommy-matrix .the-matrix.periods .matrix-row.arrangement { height: 100%;}
.tommy-matrix .matrix-row.arrangement { margin-bottom: 2px;}
.tommy-matrix .matrix-row .fixed-one { top: 6px;}
.tommy-matrix .periods .matrix-row .fixed-one {top: 4px; height: 60px !important;}
.tommy-matrix .the-matrix.periods .matrix-row .item-row { height: 60px;}
.tommy-matrix .matrix-row.arrangement .sticky-point { margin: 0;}
.tommy-matrix .matrix-bar label > select { margin: 5px 0;}
.tommy-matrix .matrix-bar .persons-table td input { margin: 0 auto; height: 30px; border: none; }
.tommy-matrix .matrix-bar .input-container { border: none; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); margin: -20px 0 0; z-index: 9999;}
.tommy-matrix .the-matrix::after,
.tommy-matrix .the-matrix::before { z-index: 99; background: linear-gradient(to right, rgba(77, 143, 186, 0) 0%,rgba(77, 143, 186, 1) 100%);}
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point { margin-top: 2px;}
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point .tooltipstered { height: inherit; transition: all 0.5s ease; margin-right: 20px;}
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point .tooltipstered:hover { background: #f39c12;}
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point .tooltipstered:hover .title, 
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point .tooltipstered:hover .date,
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point .tooltipstered:hover .price { color: #fff;}
.tommy-matrix .the-matrix .popup .input-container_header h2 { font-family: inherit;}

.tommy-matrix .time-bar { z-index: 100;}
.tommy-matrix .time-bar .time-back {
    padding-left: 0;
    background: linear-gradient(to right, rgba(77, 143, 186, 1) 0%, rgba(77, 143, 186, 0) 100%);
}
.tommy-matrix .time-bar .time-ff {
    background: linear-gradient(to right, rgba(77, 143, 186, 0) 0%, rgba(77, 143, 186, 1) 100%);
}

@media (max-width: 768px) {
    .tommy-matrix .the-matrix.periods .popup { width: auto; height: auto; margin: 0; top: 15px !important; left: 15px !important; right: 15px !important; bottom: inherit;}
    .tommy-matrix .matrix-bar .flex-row > .flex-col,
    .tommy-matrix .the-matrix .flex-row > .flex-col { margin: 0; flex: 1 1 100%;}
    .tommy-matrix .matrix-date-row .matrix-time { font-size: 12px;}
}
@media (max-width: 576px) {
    .tommy-matrix .the-matrix button, .tommy-matrix .the-matrix .btn { width: 100%;}
}

/* BACKGROUND-COLOR CONTAINER/BODY */
.tommy-matrix .matrix-row .fixed-one {
    width: 205px;
    border-left: 0;
}
.tommy-matrix .matrix-row .fixed-one .fixed-one_image { display: none;}
.tommy-matrix .matrix-row .fixed-one .fixed-one_text {
    width: 200px;
    padding: 10px;
    background-color: #032744;
    border-right: none;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
}
.tommy-matrix .matrix-row .fixed-one .fixed-one_text .more-info {
    left: 10px;
    margin-bottom: 10px;
}
.tommy-matrix .matrix-row .item-row { padding-left: 205px;}
.tommy-matrix .matrix-date-row .date-row { padding-left: 205px;}

.tommy-matrix .the-matrix.periods .matrix-row .fixed-one .fixed-one_text { border-right: 2px solid #fafbfd;}

/* CUSTOM CSS */
.tommy-matrix .matrix-bar label { background: none; height: auto; margin-bottom: 15px;}
.tommy-matrix span.label-name { font-size: 16px; margin-bottom: 5px;}
.tommy-matrix .matrix-bar label.active textarea,
.tommy-matrix .matrix-bar label.active input,
.tommy-matrix .matrix-bar label.active select { color: #000;}
.tommy-matrix .matrix-row.arrangement .fixed-one { border-color: #1b2f52;}
.tommy-matrix .matrix-bar .input-ok input,
.tommy-matrix .matrix-bar .input-ok select { color: #000;}
.tommy-matrix .matrix-bar label.input-ok::after,
.tommy-matrix .matrix-beschikbaarheid .matrix-prijs,
.tommy-matrix .time-bar .time-back,
.tommy-matrix .time-bar .time-ff { color: #fff; }
.tommy-matrix .matrix-beschikbaarheid .matrix-trigger,
.tommy-matrix .matrix-bar .persons-table td a,
.tommy-matrix span.label-name { color: #000; }
.tommy-matrix .matrix-date-row .matrix-time { color: #fff; font-size: 14px; font-weight: 600;}

/* cells */
.tommy-matrix .matrix-beschikbaarheid .matrix-prijs { font-size: 16px; font-weight: 600; color: inherit;}
.tommy-matrix .matrix-beschikbaarheid { background: #fff; margin: 0 1px; border-radius: 3px; color: #005caa;}
.tommy-matrix .matrix-beschikbaarheid.no-data { background: rgba(255, 255, 255, 0.5);}
.tommy-matrix .matrix-beschikbaarheid:not(.no-data):hover { background: #005caa; color: #fff;}
.tommy-matrix .matrix-bar .persons-table td a:hover,
.tommy-matrix .time-bar .time-back:hover,
.tommy-matrix .time-bar .time-ff:hover { color: #1b2f52;}
.tommy-matrix .matrix-beschikbaarheid:hover .matrix-prijs { color: #fff;}
/* popup */
.tommy-matrix .the-matrix .popup { border: none; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); margin: 24px 0 0 55px; z-index: 9999; }
.tommy-matrix .the-matrix .popup .input-container_header { background: #005caa;}
.tommy-matrix .the-matrix .popup .show-col-table tr td { color: #000;}
.tommy-matrix .the-matrix .popup .show-col-table tr.td-total td { color: #000;}
.tommy-matrix .the-matrix button, .tommy-matrix .the-matrix .btn { background: #fff; box-shadow: none; border: 1px solid #1b2f52; border-radius: 4px; color: #1b2f52; font-size: 16px; font-weight: 700; text-transform: uppercase;}
.tommy-matrix .the-matrix button:hover, .tommy-matrix .the-matrix .btn:hover{ background: #1b2f52; color: #fff; }
.tommy-matrix .the-matrix .popup .input-container_header::before { display: none; content: ''; position: absolute; top: -10px; left: 15px; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid #005caa; }
.tommy-matrix .popup .close i.fa { font-size: 20px; }

.tommy-matrix .matrix-row.arrangement .row-arrang {
background: #e9ecf5;
border-left-color: #1b2f52;
box-shadow: inset 0px 1px 1px 0px #fff;
}
.tommy-matrix .row-arrang .title,
.tommy-matrix .row-arrang .price { color: #1b2f52; padding-right: 100px; display: flex; flex-direction: column; text-align: right; }
.tommy-matrix .row-arrang .price .vanvoor { position: relative; }
.tommy-matrix .row-arrang .title { width: 30%; padding: 0; }

/* new */
.tommy-matrix .matrix-bar { margin: 0 auto;}
.tommy-matrix .matrix-bar label:not(.checkbox):not(.btn) { background: transparent;}
.tommy-matrix .matrix-bar span.label-name {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}
.tommy-matrix .matrix-bar label > input,
.tommy-matrix .matrix-bar label > select {
    border-color: #fff;
    background-color: #fff;
    font-size: 12px;
}
.tommy-matrix .matrix-date-row .fixed-one {
    width: 205px;
    background-color: #4d8fba !important;
}

@media (max-width: 768px) {
    .matrix-row .fixed-one .fixed-one_text {
        width: 90px !important;
        overflow: hidden;
    }
    .matrix-row .fixed-one,
    .matrix-date-row .fixed-one {
        width: 95px !important;
    }
    .matrix-date-row .date-row,
    .matrix-row .item-row {
        padding-left: 95px !important;
    }
    .the-matrix.periods .matrix-row .fixed-one {
        width: 95px !important;
    }
}
