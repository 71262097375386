@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body.default {
	@import '~framework/assets/scss/Dev/base';

	@import '2_layout/base';
	@import '2_layout/header';
	@import '2_layout/footer';
	@import '2_layout/content';
	@import '2_layout/container';

	@import '3_components/buttons';
	@import '3_components/cards';
	@import '3_components/owl-carousel';

	@import '6_theme/page.home';
	@import '6_theme/page.two_columns';
	@import '6_theme/page.one_columns';
	@import '6_theme/page.three_columns';
	@import '6_theme/page.landing';
	@import '6_theme/page.landing_extra';
	@import '6_theme/page.contact';
	@import '6_theme/module.service';
	@import '6_theme/module.accomodation';
	@import '6_theme/module.blog';
}

@import './6_theme/tommy.booking';
@import './6_theme/tommymatrix';
