.footer {
	margin-top: 100px;
	@include media-breakpoint-up(md) {
		margin-top: 210px;
	}
	padding: 0;

	.footer-top {
		background-color: $bg-base-6;
		padding: 30px 0 35px;

		.container-default {
			.container-holder {
				.outro-text.page_block.footer_text {
					@include media-breakpoint-up(sm) {
						text-align: center;
					}

					h1 {
						padding-top: 30px;
						font-size: $font-size-base * 1.375;
						font-weight: 800;
						color: $white;

						@include media-breakpoint-up(lg) {
							font-size: $font-size-base * 1.75;
						}
					}

					> p {
						font-size: $font-size-base_sm;
						color: $white;
						line-height: 1.3;
					}
				}

				.contact-icons.page_block.vocabulary_term_list {
					display: block !important; // !important was needed to override base styles
					margin: 15px 0;

					@include media-breakpoint-up(sm) {
						display: flex !important; // !important was needed to override base styles
						justify-content: center;
					}

					li {
						margin: 10px 0 0;

						@include media-breakpoint-up(sm) {
							width: 255px;
							margin-bottom: 15px;
						}

						@include media-breakpoint-only(md) {
							width: 180px;
						}

						@include media-breakpoint-only(lg) {
							width: 235px;
						}

						@include media-breakpoint-only(xl) {
							width: 185px;
							margin-bottom: 0;
						}

						a {
							display: flex;
							color: $white;
							font-size: $font-size-base_sm;
							font-weight: 700;
							text-decoration: none;

							@include media-breakpoint-up(md) {
								flex-flow: column wrap;
								text-align: center;
							}

							i[class^="fa"] {
								font-size: $font-size-base * 1.5;
								margin: 0 16px 8px 0;

								@include media-breakpoint-up(md) {
									margin: 0 0 8px;
								}
							}

							&:hover {
								opacity: 0.6;
							}
						}
					}
				}
			}
		}
	}

	.footer-bottom {
		background: $secondary;
		display: block;
		padding-top: 80px;

		@include media-breakpoint-up(xl) {
			display: flex;
		}

		.container-four-columns {
			.container-holder {
				.column {
					flex: 0 0 50%;
					max-width: 50%;
					padding-bottom: 30px;

					@include media-breakpoint-up(md) {
						flex: 0;
						max-width: 0;
						min-width: 170px;
					}
					@include media-breakpoint-up(lg) {
						padding-bottom: 0;
					}

					.page_block.footer_list {
						@include media-breakpoint-down(lg) {
							margin-bottom: 30px;
						}

						h5 {
							font-size: $font-size-base_sm;
							color: $white;
						}

						.list {
							&-item {
								line-height: 1.2;

								.link {
									position: relative;
									padding-left: 24px;
									font-size: $font-size-base_sm;
									font-weight: 400;
									color: $white;
									text-decoration: none;

									&:hover {
										text-decoration: underline;

										&::before {
											margin-left: 3px;
										}
									}

									&::before {
										position: absolute;
										top: 50%;
										left: 0;
										content: "\F178";
										transform: translateY(-50%);
										font-size: $font-size-base_sm;
										font-family: $font-awesome;
										transition: all 300ms;
									}
								}
							}
						}
					}
				}
			}
		}

		.footer_logolink {
			flex: 0 0 auto;
			margin: 0 15px;

			.list {
				margin: 0 -15px;
				align-items: center;

				&-item {
					margin: 0 15px 15px 15px;

					.link {
						display: block;
						color: $white;
						font-size: $font-size-base * 1.5;

						img {
							max-width: 150px;
							max-height: 60px;
						}
					}
				}
			}
		}
	}

	.footer-socket {
		background: $secondary;
		padding: 40px 0;

		.container-default {
			.container-holder {
				.footer_link {
					.list {
						.list-item {
							&:not(:last-child) {
								margin-right: 20px;
							}

							.link {
								font-size: $font-size-base_sm;
								font-weight: 400;
								color: $white;
								text-decoration: none;

								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}
}
