&.accommodation_search_book {
	.page-title-section {
		padding-bottom: 0;
		color: $white;
		.container-holder {
			flex-direction: column;
			align-items: stretch;
		}
	}

	.content {
		padding-top: 0;

		.container {
			max-width: none;
			padding-left: 0 !important;
			padding-right: 0 !important;

			.w3-filter-collection {
				background-color: #4D8FBA;
			}

			.filters,
			.tags,
			.results {
				max-width: 1140px;
				padding-left: 15px;
				padding-right: 15px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

&.accommodation_overview,
&.accommodation_category_overview {
	.main {
		.content.content-narrow-wide {
			padding: 60px 0;

			.container-two-columns {
				.container-holder {
					.column.one {
						@include media-breakpoint-up(md) {
							padding-left: 15px;
						}

						.back_to_overview_link {
							.btn-back {
								&::before {
									@extend .btn-absolute;
									transform: translateY(-15%) rotate(180deg);
								}
							}
						}

						h1 {
							color: $white;
							text-align: right;
							line-height: 1;
							margin-bottom: 35px;
						}

						h3 {
							color: $primary;

							a {
								position: relative;
								padding-right: 40px;

								&:hover {
									text-decoration: none;

									&::after {
										right: 15px;
										transition: 0.2s ease-in;
									}
								}

								&::after {
									position: absolute;
									top: 50%;
									right: 20px;
									content: "\F178";
									transform: translateY(-50%);
									font-size: $font-size-base_sm;
									font-weight: 400;
									font-family: $font-awesome;
									transition: all 300ms;
								}
							}
						}

						p {
							line-height: 1.7;

							a {
								margin-bottom: 20px;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

&.accommodation_category_overview {
	.main {
		.content.content-narrow-wide {
			.container-two-columns {
				.container-holder {
					.column.one {
						@include make-col(12);
						padding-right: 15px !important;

						h1 {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.grid {
		.item {
			@include media-breakpoint-up(xl) {
				@include make-col(4);
				.card {
					.card-image {
						.card-image-link {
							overflow: hidden;

							&:hover {
								.card-img {
									transform: scale(1);
								}
							}

							.card-img {
								width: 100%;
								height: auto;
								transition: all 0.5s ease-in-out;
								transform: scale(1.2);
							}
						}
					}

					.card-body {
						margin-top: 15px;
					}
				}
			}
		}
	}
}

&.accommodation_detail,
&.accommodation_category_detail {
	.main {
		.content {
			padding-bottom: 0;
			background: $bg-base-1 !important;

			.container-holder {
				.accommodation_detail {
					margin: 0;
					padding-bottom: 0 !important;

					.intro {
						@extend .container;

						@include media-breakpoint-up(md) {
							display: flex;
							align-items: self-start;
						}

						.info {
							flex: 0 0 35%;
							max-width: 35%;

							@include media-breakpoint-down(sm) {
								flex: 0 0 100%;
								max-width: 100%;
							}

							.btn-back,
							h1 {
								flex: 0 1 auto;
							}

							h1 {
								padding-left: 30px;
							}

							p {
								flex: 0 1 100%;
								max-width: 350px;
								line-height: 1.7;
							}

							.btn-back {
								&:hover {
									&::before {
										margin-left: -5px;
										transition: 0.3ms ease-in-out;
									}
								}

								&::before {
									color: $white;
									@extend .btn-absolute;
									@extend .btn-back-to-page;
									transform: translateY(-15%) rotate(180deg);
								}

								&::after {
									display: none;
								}
							}

							h1 {
								color: $white;
								text-align: right;
								line-height: 1;
								margin-bottom: 0;
							}

							h2 {
								text-align: right;
								color: #032744;
							}

							h3 {
								font-size: 1.125rem;
								line-height: 1.2;
								color: #032744;
								margin-top: 55px;
							}

							p {
								max-width: none;
								line-height: 1.7;

								a {
									font-weight: 600;
									text-decoration: underline;
									display: inline-flex;
									padding-left: 8px;
									position: relative;

									&:after {
										font-family: "Font Awesome 5 Pro";
										font-size: 14px;
										font-weight: 600;
										content: "\f178";
										position: absolute;
										right: -10px;
										transform: translateX(100%);
									}
								}
							}
						}

						.info-extra {
							flex: 0 0 65%;
							max-width: 65%;

							@include media-breakpoint-down(sm) {
								flex: 0 0 100%;
								max-width: 100%;
							}

							.owl-carousel {
								@include media-breakpoint-down(sm) {
									display: none;
								}
							}
						}

						.accommodation-properties {
							flex-wrap: wrap;
							margin: 30px 0 16px;
							border-bottom: 1px solid $white;

							.accommodation-properties__item {
								font-size: 14px;
								padding-left: 0;

								&:before {
									display: none;
								}

								.accommodation-properties__icon {
									i[class^="fal"] {
										color: $white;
										font-weight: 600;
									}
								}
							}

							.accommodation-properties__label {
								color: $white;
							}
						}

						ul {
							padding: 0;
							list-style-type: none;

							li {
								font-size: 14px;
								color: $primary;
								padding-left: 30px;
								font-weight: normal;
								margin-bottom: 8px;

								&:before {
									font-family: "Font Awesome 5 Pro";
									font-size: 14px;
									font-weight: 600;
									content: "\f00c";
									position: absolute;
									left: 17px;
								}
							}
						}
					}

					.image-gallery {
						.gallery {
							margin: 0 !important;
							padding: 0;

							.gallery-item {
								margin: 0;
								padding: 0;

								&:hover {
									transform: scale(1);
								}
							}
						}
					}

					.acco-widget {
						background-color: #4d8fba;
						padding: 50px 0;
						width: 100%;

						.tommy,
						#TommyBookingSupport {
							@extend .container;
						}
					}
				}
			}
		}
	}
}

&.accommodation_category_detail {
}

.main {
	.content {
		.container-holder {
			.accommodation_detail {
				.info {
					.btn-back,
					h1 {
						flex: 0 1 auto;
					}

					.btn-back {
						&:hover {
							&::before {
								margin-left: -5px;
								transition: 0.3ms ease-in-out;
							}
						}

						&::before {
							@extend .btn-absolute;
							@extend .btn-back-to-page;
							transform: translateY(-15%) rotate(180deg);
							font-size: $font-size-base * 1.75;
						}
					}

					h1 {
						padding-left: 30px;
						text-align: right;
						line-height: 1;
						margin-bottom: 35px;
					}
				}
			}
		}
	}
}
