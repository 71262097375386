.card {
	background: $card-background;
	border: none;
	box-shadow: none;
	border-radius: 0;

	.card-body {
		padding: 0;

		.card-caption {
			flex: none;

			.card-title-link {
				.card-title {
					font-size: $font-size-base * 1.125;
					color: $primary;
				}
			}

			.card-description {
				color: $primary;

				&::after {
					background: transparent;
				}
			}
		}
	}
}

.page_block.card-columns {
	.item {
		.card {
			margin: 0 0 60px 0;
			display: inline-flex;

			.card-image {
				order: 2;
				margin-top: 16px;

				.card-image-link {
					overflow: hidden;

					&:hover {
						.card-img {
							transform: scale(1);
						}
					}

					.card-img {
						width: 100%;
						height: auto;
						transition: all 0.5s ease-in-out;
						transform: scale(1.2);
					}
				}
			}

			.card-body {
				order: 1;

				.card-caption {
					.card-title-link {
						.card-title {
							position: relative;

							&:hover {
								&::after {
									margin-left: 15px;
									transition: 0.2s ease-in;
								}
							}

							&::after {
								position: absolute;
								top: 50%;
								content: "\F178";
								transform: translateY(-50%);
								margin-left: 10px;
								font-size: $font-size-base_sm;
								font-weight: 400;
								font-family: $font-awesome;
								transition: all 300ms;
							}
						}
					}

					.card-subtitle {
						color: $primary;
						font-weight: 400;
					}

					.card-description {
						color: $black;

						ul {
							padding: 15px 0 0;

							li {
								list-style-type: none;
								font-size: $font-size-base_xxs;
								line-height: 1;
								margin-bottom: 15px;
								font-weight: 200;

								a {
									position: relative;
									color: $black;
									font-size: $font-size-base_sm;
									font-weight: 500;

									&:hover {
										text-decoration: underline;
										color: lighten($black, 10);

										&::after {
											margin-left: 15px;
											transition: 0.2s ease-in;
										}
									}

									&::after {
										position: absolute;
										top: 50%;
										content: "\F178";
										transform: translateY(-50%);
										margin-left: 10px;
										color: $primary;
										font-size: $font-size-base_sm;
										font-weight: 400;
										font-family: $font-awesome;
										transition: all 300ms;
									}
								}
							}
						}

						.list.list-icons {
							display: flex;
							flex-wrap: wrap;

							.list-item {
								font-size: $font-size-base;
								font-weight: 400;

								&:not(:last-child) {
									margin-right: 16px;
								}

								.icon {
									i[class^="fal"] {
										font-weight: 600;
									}
								}
							}
						}

						p {
							margin-bottom: 0;
						}
					}
				}

				.card-buttons {
					margin: 0;

					.card-btn {
						display: none;
					}
				}
			}
		}

		&:last-child .card {
			margin-bottom: 0;
		}
	}
}
