.header {
	/* Header-socket
		 ========================================================================== */

	.header-socket {
		background: $white;

		@include media-breakpoint-down(md) {
			background: $white;
			position: fixed;
			width: 100%;
			z-index: 1;
			top: 0;
		}

		.container-navbar {
			max-width: none;

			.column.navbar {
				padding: 10px;
				height: 70px;

				@include media-breakpoint-up(md) {
					height: 80px;
				}

				.menu_toggle {
					display: block !important; // !important needed to override base css
					width: 70px;
					height: 70px;
					@extend .flex-center;
					position: absolute;
					left: 0;
					top: 0;
					background: $secondary;
					border-radius: 0;
					z-index: 1;
					transition: 0.3ms ease-in-out;

					&.collapsed {
						background: $secondary;
					}

					&:hover {
						background: lighten($secondary, 10%);
					}

					@include media-breakpoint-up(md) {
						width: 80px;
						height: 80px;
					}

					&[aria-expanded="true"] {
						.fa-bars {
							&::before {
								content: "\f00d";
								font-size: $font-size-base * 1.875;
							}
						}
					}

					.navbar-toggler-icon {
						color: $white;
						background: none;
						width: auto;
						height: auto;
						font-size: $font-size-base * 1.4375;
					}
				}

				.side_panel.page_block {
					position: fixed;
					left: 0;
					top: 0;
					transform: translateX(-400px);
					width: 90%;
					height: 100%;
					max-width: 400px;
					background: $primary;
					padding: 90px 30px;
					transition: 0.5s;
					z-index: 1;

					&::before {
						content: "";
						display: none;
						opacity: 0;
						position: fixed;
						top: 0;
						left: 400px;
						right: 0;
						background: rgba($black, 0.5);
						bottom: 0;
						width: calc(100vw - 400px);
						transition: all 500ms;
					}

					&.show {
						transform: translateX(0);

						&::before {
							display: inline-block;
							opacity: 1;
						}
					}

					&.side_panel_opened {
						.nav-item {
							&:not(.active_side_panel) {
								display: block !important; //!important was needed to override base styles
							}
						}
					}

					.navbar-nav {
						width: 100%;
						flex-direction: column;
						justify-content: flex-start;
						height: 100%;
						overflow: auto;

						@include media-breakpoint-up(lg) {
							justify-content: center;
						}

						> li {
							a {
								font-size: $font-size-base;
								font-weight: 600;
								color: $white;
								padding: 0.6rem 0;

								&:hover {
									color: darken($white, 20);
									text-decoration: none;

									&::after {
										margin-left: 15px;
										transition: 0.3ms ease-in-out;
									}
								}

								&.active {
									> a {
										color: $black;
										text-decoration: underline;
									}
								}
							}

							&:not(:last-child) {
								border-bottom: 1px solid $white;
							}

							.dropdown-toggle {
								&::after {
									font-family: $font-awesome;
									font-size: $font-size-base_sm;
									font-weight: 400;
									border: none;
									position: absolute;
									top: 50%;
									margin-left: 10px;
									content: "\F178";
									transform: translateY(-50%);
									transition: 0.25s ease-in-out;
								}
							}

							&.nav-item.active_side_panel {
								position: absolute;
								height: 100%;
								width: 100%;
								max-width: 400px;
								background: $primary;
								padding: 0 30px;
								left: 0;
								z-index: 1;

								.nav-link {
									transition: none;
								}

								ul.dropdown-menu.show {
									position: initial !important;
									margin: 0;
									padding: 0 0 10px;
									border: none;
									background: none;
									width: 100%;
									display: flex;
									height: 100%;
									flex-direction: column;
									justify-content: center;

									.menu-side-panel-header {
										display: flex;
										align-items: center;
										justify-content: space-between;
										margin-bottom: 80px;
										cursor: pointer;

										.go-back {
											position: relative;

											&::after {
												font-family: $font-awesome;
												font-size: $font-size-base * 1.5;
												font-weight: 400;
												border: none;
												position: absolute;
												top: 50%;
												content: "\F178" !important; // !important needed to override base css
												transform: translateY(-50%) rotate(180deg);
											}
										}

										span {
											font-size: $font-size-base * 1.5;
											font-weight: 700;
											color: $white;
										}
									}

									.nav-item {
										&:not(:last-child) {
											border-bottom: 1px solid $white;
										}

										.nav-link {
											font-size: $font-size-base;
											font-weight: 600;
											color: $white;
											padding: 0.6rem 0;
										}
									}
								}
							}

							.dropdown-menu {
								li {
									a {
										color: #000000;
										padding-left: 5px;
									}
								}
							}
						}
					}
				}

				.site_switcher {
					margin-right: 25px;

					@include media-breakpoint-up(md) {
						margin-right: 45px;
					}

					li {
						width: 20px;
						height: 20px;
						border-radius: 100%;
						background: $white;
						overflow: hidden;

						&:not(:last-child) {
							margin-right: 10px;
						}

						a {
							font-size: $h3-font-size;
							line-height: 1;
							padding: 0 !important; // !important needed to override base css
							opacity: 0.5;

							&:hover {
								opacity: 1;
							}

							img {
								max-width: 100%;
								transform: scale(1.6);
							}
						}

						&.active {
							a {
								opacity: 1;
							}
						}
					}
				}

				.vocabulary_term_list {
					margin: 0;

					a {
						font-size: $font-size-base_sm;
						text-transform: uppercase;
						color: $secondary;
						font-weight: 600;
					}
				}
			}
		}
	}

	/* Header-main
		 ========================================================================== */

	.header-main {
		@include media-breakpoint-down(md) {
			margin-top: 60px;
		}

		nav.column.navbar {
			padding: 0;
		}

		.logo {
			transition: width 0.2ms ease-in-out;
			max-width: 250px;
			width: auto;
			margin: 40px auto;
		}
	}

	&.sticky {
		.header-main {
			.navbar {
				.logo {
					display: none;
				}
			}
		}

		.navbar-nav {
			&.language {
				display: none;
			}
		}

		.navbar {
			padding: 0;

			@include media-breakpoint-up(md) {
				.logo {
					position: absolute;
					left: 0;
					right: 0;
					margin: auto;
					top: -45px;
					max-width: 110px;
				}
			}
		}
	}

	&:not(.sticky) {
		@include media-breakpoint-up(lg) {
			.logo {
				max-width: 350px;
				margin: 0 auto 25px;
				position: relative;
				top: -20px;
			}
		}
	}
}

.side-panel-close {
	display: none;
}

.navbar-nav {
	li {
		> a {
			position: relative;
		}
	}

	.dropdown-menu {
		position: initial !important;

		.menu-side-panel-header {
			display: none;
		}

		li {
			> a {
				padding-left: 15px !important;

				&:hover {
					opacity: .8;
				}
			}
		}
	}
}
