&.blog_post_overview {
	.main {

		.content {
			.container-default {
				.container-holder {
					.page_block.card-columns {
						.item {
							.card {
								.card-body {
									.card-caption {
										display: flex;
										flex-direction: column;

										.card-title-link {
											order: 2;
										}

										.card-subtitle {
											font-size: $font-size-base_xxs;
											font-weight: 200;
											color: $black;
											margin-bottom: 5px;
											order: 1;
										}

										.card-description {
											order: 3;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

&.blog_post_detail {
	.main {
		.content {
			padding-bottom: 0;
			background: $bg-base-2 !important;
		}

		.container-holder {
			.blog_detail {
				margin: 0;
				.intro {
					@extend .container;
					margin-bottom: 60px;

					@include media-breakpoint-up(md) {
						display: flex;
					}

					.info {
						flex: 0 0 35%;
						max-width: 35%;
						@include media-breakpoint-down(sm) {
							flex: 0 0 100%;
							max-width: 100%;
						}

						.btn-back,
						h1 {
							flex: 0 1 auto;
						}
						h1 { padding-left: 30px;}

						.btn-back {
							&:hover {
								&::before {
									margin-left: -5px;
									transition: 0.3ms ease-in-out;
								}
							}

							&::before {
								color: $white;
								@extend .btn-absolute;
								@extend .btn-back-to-page;
								transform: translateY(-15%) rotate(180deg);
							}
						}

						p {
							flex: 0 1 100%;
							max-width: 350px;
							line-height: 1.7;
						}

						h1 {
							padding-left: 40px;
							text-align: right;
							line-height: 1;
							margin-bottom: 0;
						}

						h2 {
							text-align: right;
							color: #032744;
						}

						.extra {
							font-size: 12px;
						}

						h3 {
							font-size: 1.125rem;
							line-height: 1.2;
							color: #032744;
							margin-top: 0;
						}

						p {
							max-width: none;
							line-height: 1.7;
						}

						.info {
							flex: 0 0 100%;
							max-width: 100%;
							padding: 0 !important;
						}
					}

					.info-extra {
						flex: 0 0 65%;
						max-width: 65%;
						@include media-breakpoint-down(sm) {
							flex: 0 0 100%;
							max-width: 100%;
						}
					}
				}

				.image-gallery {
					.gallery {
						margin: 0 !important;
						padding: 0;

						.gallery-item {
							margin: 0;
							padding: 0;

							&:hover {
								transform: scale(1);
							}
						}
					}
				}

			}
		}
	}
}
